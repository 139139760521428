.calculator-card {
    height: auto;
    width: 100%;
    padding: 20px 40px 20px 40px;
    /* top left bottom right  */
    /* box-shadow: 0 0 6px 0 rgba(0, 15, 230, 0.2); */
    z-index: -1;
    margin-bottom: 3rem;
}

.calculator-resultcard {
    margin-top: 3.5rem;
    height: auto;
    width: 100%;
    /* padding: 20px 40px 20px 40px; */
    /* top left bottom right  */
    /* box-shadow: 0 0 6px 0 rgba(0, 15, 230, 0.2); */
    z-index: -1;
    border-radius: 5px !important;
    margin-bottom: 3rem;
}

.cal-title {
    color: #4698FF;
    font-family: var(--onest);
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
}

.calculator-dropdown {
    box-sizing: border-box;
    width: 100%;
    height: 2.2rem !important;
    font-size: 15px !important;
    font-weight: 500;
    background-color: #FBFCFD;
    color: #555555;
    background-color: #FFF !important;
    outline: none !important;
    text-align: left;
    border-radius: 5px;
    border: 1px solid #CCC;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;

}

.calculator-dropdown:focus {
    border-radius: 5px !important;
    box-shadow: 0 0 4px 0 rgba(211, 73, 91, 0.9) !important;
    border: 1px solid #CCC !important;
    outline: none;
}

.checkboxForm {
    font-size: 14px !important;
    font-family: var(--onest);
    text-align: justify;
    color: #FFF;
}

.form-check-input:checked {
    background-color: #1CA9AB;
    border-color: none !important;
    /* background-image: url(../images//accountant//check.png) !important; */
}

.form-label {
    font-size: 1rem;
    text-align: left;
    font-family: var(--onest) !important;


}

.calculator-btn {
    margin-top: 1rem;
    border-radius: 5px !important;
    background-color: #4698FF !important;
    color: #FFFFFF !important;
    text-align: center !important;
    font-size: 18px !important;
    font-family: var(--onest);
    font-weight: 600;
    padding: 10px !important;
    width: 100% !important;
    height: 45px !important;
    border: none !important;
   
    
    /* box-shadow: rgba(197, 166, 166, 0.56) 0px 20px 40px 0 !important; */
}

.calculator-btn:hover {
    background-color: #1a78ec !important;
}
.calculator-btn:focus {
    box-shadow: none!important;
}


.button-2 {
    color: #fff;
    border: 3px solid #E6001F;
    background-image: -webkit-linear-gradient(30deg, #E6001F 50%, transparent 50%);
    background-image: linear-gradient(30deg, #E6001F 50%, transparent 50%);
    background-size: 690px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 400ms ease-in-out;
    transition: background 400ms ease-in-out;
}

.button-2:hover {
    background-position: 100%;
    color: #fff;
    background-color: #000 !important;
    border: 3px solid #E6001F !important;

}
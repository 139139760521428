body {
    margin: 0;
    padding: 0;
    border: 0;
    overflow-x: hidden;
    /* overflow: hidden; */
    width: 100%;
    /* box-sizing: border-box; */
    /* font-size: 16px; */

}

html {
    font-size: 100%;
    margin: 0;
    padding: 0;
    border: 0;
}


/* ....Trade part above footer.... */

.tradePart {
    width: 1140px;
    height: 190px;
    max-width: 1140px;

    /* align-content:flex-start; */
}




/* ...............Resourses FAQ Navbar .......... */

.faqnavbar {
    width: 100%;
    background-color: #E6001F;
    overflow: auto;
    cursor: pointer;
}

.faqnavbar a {
    float: left;
    padding: 7px;
    color: white !important;
    text-decoration: none;
    font-family: 'Helvetica', Arial, Lucida, sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    box-sizing: border-box;
    width: 20%;
    /* Four links of equal widths */
    text-align: center;
    border-left: 3px solid #fff;
}


.faqnavbar a:hover {
    background-color: #ee485e;

}

.faqnavbar a.faqactive {
    background-color: #273044;
}


@media screen and (max-width: 500px) {
    .faqnavbar a {
        float: none;
        display: block;
        width: 100%;
        text-align: left;
    }
}

/* ...............Resourses FAQ TopBanner............... */

.FAQBannerTopBanner {
    /* background-image: url("../images/header-meeting2.jpg"); */
    min-height: 380px;
    background-repeat: no-repeat;
    width: 100%;
    /* position: fixed; */
    background-size: cover;
    /* background-position: center; */
    /* background-attachment: fixed !important; */
}








/* ............Navbar FAQ SECTION.............. */

.Ncard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    height: auto;
}

/* .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  } */


.Ncollapsible {
    background-color: #273044;
    color: white;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    border-bottom: 5px solid #F2F2F2;
}

.Nactive,
.Ncollapsible:hover {
    /* background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(239, 102, 118, 1) 35%, rgba(0, 212, 255, 1) 100%); */
    color: #F2F2F2;
    background-color: #EF6676;
}

.Ncollapsible:after {
    content: '\002B';
    color: white;
    font-weight: 700;
    text-align: center;
    float: right;
    margin-left: 5px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    /* background-color: #E6001F; */
}

.Nactive:after {
    content: "\2212";
    border-radius: 50%;
    text-align: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    /* background-color: #273044; */
}

.Ncontent {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #ffffff;
}

.NavFAQTitle {
    color: #000;
    font-size: 34px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px;
}

/* ....All Indutry news section detailspage... */
.accContainer {
    background-color: #F7F7F7;
}

.insNewsImg {
    height: 35rem;
    width: 100%;
    background-image: cover;
    object-fit: cover;
    /* object-position: bottom;  */
    background-repeat: no-repeat;
    /* padding: 20px; */

}

.insNewsImgMiddle {
    min-height: 220px;
    max-width: 330px;
    background-image: cover;
    background-repeat: no-repeat;
    padding: 20px;

}

.ImgMiddleFullWidth {
    min-height: 150px;
    max-width: 100%;
    background-image: cover;
    background-repeat: no-repeat;
    padding: 20px;

}

.insNewsTitle {
    color: #000;
    font-size: 2rem;
    font-family: Arial, 'Helvetica', sans-serif;
    font-weight: 600;
    margin: .7rem;
    line-height: 3rem;

}

.insNewssubTitle {
    color: #000;
    font-size: 28px;
    font-family: Arial, 'Helvetica', sans-serif;
    font-weight: 600;
    margin: 10px;
    line-height: 4rem;
    text-align: justify;

}

.insNewssubTitle2 {
    color: #000;
    font-size: 24px;
    font-family: Arial, 'Helvetica', sans-serif;
    font-weight: 600;
    margin: .7rem;
    line-height: 3rem;
    font-style: italic;
}

.insNewssubTitle3 {
    color: #000;
    font-size: 25px;
    font-family: Arial, 'Helvetica', sans-serif;
    font-weight: 600;
    margin: .7rem;
    line-height: 3rem;
    text-decoration: underline;

}

.insNewsdetailsDesc {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 2rem;
    color: #555555;
    margin: .7rem;
    text-align: justify;
    font-family: Arial, 'Helvetica', sans-serif;

}

.blog-link-style {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 2rem;
    color: #4330ad;
    text-align: justify;
    font-weight: 500;
    text-decoration: underline;
    font-family: Arial, 'Helvetica', sans-serif;

}

.InsdustryNewSce {
    background-color: #FFFFFF;
    min-height: auto;
    z-index: -1;
    max-width: 100%;
    /* box-shadow: 0 0 4px 0 rgba(0, 15, 230, 0.2); */
    padding: 25px 25px 25px 25px;
    margin-bottom: 3rem;
    box-sizing: border-box;
}

.view-All-Post {
    border-radius: 4px;
    background-color: #ff135a !important;
    border: none !important;
    color: #FFFFFF !important;
    text-align: center !important;
    font-size: 16px !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600 !important;
    padding: 10px !important;
    width: 170px !important;
    height: 45px !important;
    transition: all 0.5s !important;
    cursor: pointer !important;
    margin: 5px !important;
    border: none !important;
}

.view-All-Post span {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    transition: 0.5s !important;
}

.view-All-Post span:after {
    content: '\00bb' !important;
    position: absolute !important;
    opacity: 0 !important;
    top: 0 !important;
    right: -20px !important;
    transition: 0.5s !important;
}

.view-All-Post:hover span {
    padding-right: 25px !important;
}

.view-All-Post:hover span:after {
    opacity: 1 !important;
    right: 0 !important;
}

.industryLink {
    color: #E6001F;
    font-size: 17px;
    font-family: 'Helvetica', Arial, Lucida, sans-serif;
    font-weight: 600;
}

.forCapcha {
    max-width: max-content;

}
@import url('https://fonts.googleapis.com/css2?family=Young+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Onest&display=swap');


.element {
    font-family: 'Young Serif', serif;
}

.another-element {
    font-family: 'Onest', sans-serif;
}
html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /* overflow-x: hidden; */
}

/* CSS Variables */

:root {
    --about-title-color: #252E44;
    --primary: #FF135A;
    --secondary-color: #252E44;
    --neutral: #686B72;
    --primary-color: #FF135A;
    --title-color: #292929;
    --text-color: #777777;
    --border-color: #201f1f17;
    --gradient-color: linear-gradient(90deg, #E6001F 0%, #FA8072 100%);
    --shadow-color: 0px 10px 30px rgba(118, 85, 225, 0.3);
    --box-shadow: 0px 10px 30px rgba(57, 56, 61, 0.205);


    /* font family variables. */
    --young: 'Young Serif', serif;
    --onest: 'Onest', sans-serif;
}

/* Style for the overall notification container */
.notification {
    background-color: var(--secondary-color, #252E44);
    top: 0;
    left: 0;
    width: 100%;
    padding: .5rem 1rem .5rem 1rem;
    z-index: 9999;

}

.message {
    font-size: .9rem;
    margin-top: 0;
    color: #E6E6E6;
}

.sub-description {
    font-size: .9rem;
    margin-top: 0;
    color: #1CA9AB;
}

.notification-icon {
    font-size: 20px;
    margin-right: 10px;
}

.close-button {
    padding: 0;
    border: none;
}

.close-icon {
    font-size: 1.5rem;
    color: #898686;
    transition: transform 0.2s ease;
}

.close-button:hover .close-icon {
    transform: scale(1.2);
    /* Scale up the icon on hover */
}

/* end top-notificaton  */
/* only for mobile */
.quoteBtnForSmallScreen {
    border-radius: 4px !important;
    background-color: #FF135A !important;
    color: #FFFFFF !important;
    text-align: center !important;
    font-size: 1.125rem !important;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 500;
    padding: .5rem !important;
    width: 100% !important;
    cursor: pointer !important;
    /* margin: 5px !important; */
    border: none !important;
    display: none;
}

/* TopUpButton.css */

.top-up-button {
    display: none;
    /* Initially hide the button */
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: 1px solid rgb(235, 227, 227);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 1000;
}

.top-up-button.show {
    display: block;
}

.arrow-icon {
    font-size: 22px;
}


/* start top-Navigation  */
.vpm-logo {
    height: 3.5rem;
    /* height: auto; */
    z-index: -1;
    max-width: 100%;
    padding: .5rem;
}

.navbar {
    position: sticky !important;
    top: 0;
    z-index: 999 !important;
    /* max-width: 100% !important; */
    /* height: 4.5rem !important; */
    background-color: #F7F8F9;
    box-shadow: 0 0 4px 0 rgba(0, 15, 230, 0.2);
    padding: 25px 0px 25px 0px;
}

/* Style for the nav links */
.nav-link {
    text-decoration: none !important;
    font-family: var("--onest");
    font-weight: 500;
    font-size: 14px !important;
    color: #505D68 !important;
    /* margin-left: .2rem; */
    /* transition: color 0.3s ease-in-out; */
    /* Add a transition for the color change */
    /* position: relative; */
    /* Set position to relative for proper scaling */
}

/* Style for the underline */
/* .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    background-color: #FF135A;
    transition: width 0.4s ease-in-out, transform 0.3s ease-in-out;
    transform-origin: center bottom;

} */

/* Style for the active nav link */
.nav-link.active,
.nav-link:hover {
    color: #FF135A;
}

/* Style for the scaling effect */
.nav-link:hover::before {
    /* width: 4.5%; */
    /* Expand the width to 100% on hover */
    /* transform: scaleX(1.05); */
    /* Scale the underline horizontally from the center on hover */
}

.nav-item {
    text-decoration: none !important;
    font-family: var("--onest");
    font-weight: 500;
    font-size: .875rem;
    color: #505D68 !important;
    /* background-color: orange; */
    /* margin-left: .4rem; */
    margin-top: .5rem;
}

.see-demo {
    text-decoration: none !important;
    font-family: var(--onest);
    font-weight: 600;
    font-size: .875rem;
    color: #1CA9AB !important;
}

.nav-item:hover {
    color: #FF135A !important;
    transition-delay: 5s;
}

.nav-item.active {
    color: #FF135A !important;
}

.nav-link active {
    color: #FF135A !important;
}

/* Style for the underline */
/* .nav-item::before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    background-color: #FF135A;
    transition: width 0.4s ease-in-out, transform 0.3s ease-in-out;
    transform-origin: center bottom;

} */

/* Style for the active nav link */

/* Style for the scaling effect */
.nav-item:hover::before {
    width: 90%;
    /* Expand the width to 100% on hover */
    transform: scaleX(1.05);
    /* Scale the underline horizontally from the center on hover */
}

/* dropdown mouse over css */

.nav-item.dropdown:hover .dropdown-menu {
    display: block !important;
}

#collasible-nav-dropdown {
    text-decoration: none !important;
    font-family: var("--onest");
    font-weight: 500;
    font-size: 15px !important;
    color: #505D68 !important;
    /* line-height: 1.19rem; */
    cursor: pointer;
}


#collasible-nav-dropdown:hover {
    color: #FF135A !important;
    /* transition: height 0.8s ease-out !important; */
}

.dropdown-toggle:after {
    display: inline-block !important;
    color: #273044;
}

.dropdown-menu {
    top: 90% !important;
    /* min-width: 27rem !important; */
    font-size: .875rem !important;
    color: #212529 !important;
    background-clip: padding-box;
    background-color: #fff;
    font-family: var(--onest) !important;
    font-weight: 500 !important;

}

.dropdown-menu:hover::before {
    /* width: 4%; */
    /* Expand the width to 100% on hover */
    /* transform: scaleX(1.05); */
    /* Scale the underline horizontally from the center on hover */
}

/* MultiLabelNavigation.css */
.services-icon {
    width: 4.8125rem;
    height: 3.5rem;
    margin-right: 1rem;
}

.services-icon2 {
    width: 21.75rem;
    height: 10.625rem;
    margin-right: 1rem;
}

/* Hide the default dropdown-toggle icon only for the specific NavDropdown */
.custom-dropdown .dropdown-toggle::after {
    display: none !important;
}

.image-and-text {
    display: flex;
    align-items: center;
    /* width: 300px;  */
}

/* Customize the sub-dropdown to align right */
.dropdown-menu2 {
    position: relative;
    top: 0;
    left: 100%;
    /* background-color: #000!important; */

}

.topnav .icon {
    display: none;
}

.navHr {
    display: none !important;
}

/* its use for active dropdown link */
.dropdown-item {
    background-color: #fff !important;
}

.custom-dropdown-item {
    width: 53rem !important;
}

.dropdown-item.active {
    color: #FF135A !important;
    background-color: #FFF !important;
}

.login-btn {
    color: #6B6B6B !important;
    text-align: center;
    font-size: .95rem !important;
    font-weight: 600 !important;
    width: 7.5rem !important;
    min-height: 2.5rem !important;
    padding: 14px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #DEDEDE !important;
    background: #E8E8E8 !important;
}

.scroll-link {
    cursor: pointer;
    /* Change the cursor style to a pointer */
}

/* ....animated navButtonLink.... */
.nav-button {
    background-color: var(--primary-color) !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
    font-family: var(--onest);
    min-height: 2.5rem !important;
    width: 8.25rem !important;
    /* margin-left: 1.2rem; */
    font-weight: 500 !important;
    font-size: .95rem !important;
    border-radius: 5px !important;
    transition: all 0.5s !important;
    cursor: pointer !important;
    border: none !important;
    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */
}

.nav-button span {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    transition: 0.5s !important;
}

.nav-button span:after {

    /* content: '\00bb' !important; */
    content: '\003E' !important;
    position: absolute !important;
    opacity: 0 !important;
    top: 0 !important;
    right: -20px !important;
    transition: 0.5s !important;
}

.nav-button:hover span {
    padding-right: 25px !important;
}

.nav-button:hover span:after {
    opacity: 1 !important;
    right: 0 !important;
}





/* end top-navigation  */



/* start top banner */
.section-gap {
    margin-top: 6rem;
}

.section-bottom {
    margin-bottom: 5rem;
}


.common-title {
    color: #252E44;
    /* font-size: 40px; */
    font-size: 2.5rem;
    font-family: var(--young);
    font-weight: 400;
    line-height: 125%;
    /* text-align: justify; */
}

.common-title2 {
    color: #292929;
    font-family: var(--young);
    font-size: 2rem;
    font-weight: 400;
    line-height: 44px;
}

.common-title3 {
    color: #779EFF;
    font-family: var(--young);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.common-title4 {
    color: #779EFF;
    font-family: var(--onest);
    /* font-size: 28px; */
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 135.714%;
}

.common-p {
    color: #656363;
    font-family: var(--onest);
    font-size: 1rem;
    font-weight: 400;
    line-height: 175%;
    /* text-align: justify; */
    /* 162.5% */
}

.common-p2 {
    color: #3D5F87;
    font-family: var(--onest);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    /* 162.5% */
}

.common-p3 {
    color: #3D5F87;
    font-family: var(--onest);
    font-size: .875rem;
    /* 14px */
    font-weight: 400;
    line-height: 1.375rem;
    text-align: justify;
    /* 162.5% */
}

.common-p4 {
    color: #6B6B6B;
    font-size: .9375rem;
    font-family: var(--onest);
    font-weight: 400;
    line-height: 1.5rem;
}
.common-p5 {
    color: #292929;
    font-family: var(--onest);
    /* font-size: 20px; */
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 136.364%;

}

.common-p6 {
    color: #252E44;
    font-family: var(--young);
    /* font-size: 20px; */
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;

}

.common-p7 {
    color: #F0F0F0;
    font-family: var(--onest);
    /* font-size: 20px; */
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
}

.common-p8 {
    color: #252E44;
    font-family: var(--onest);
    /* font-size: 20px; */
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
}

.common-p9 {
    color: #7A7A7A;
    font-family: var(--onest);
    /* font-size: 28px; */
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 142.857%;
}

.common-p10 {
    font-family: var(--onest);
    font-weight: 600;
    color: #ffffff;
    font-size: 1.375rem;
}

.offer-text {
    font-size: 14px;
    font-family: var(--onest);
    text-align: justify;
    font-style: italic;
    color: #FFF;
    font-weight: 400;
    line-height: 142.857%;
}

.imoj {
    font-size: 20px;
    color: transparent;
    text-shadow: 0 0 0 #FF135A;
}

.list-item {
    margin-top: 10px;
    color: #686B72;
    font-family: var(--onest);
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
}

.banner-title {
    font-family: var(--young);
    font-size: 3.1875rem;
    font-weight: 400;
    line-height: 3.75rem;
    text-shadow: 4px 3px 4px rgba(85, 120, 205, 0.25);
    background: linear-gradient(273deg, #D2232A 33.34%, #252E44 77.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-p {
    color: #000;
    font-family: var(--young);
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 200%;
    /* width: 80%; */
}

.banner-img {
    margin-top: 7rem;
    /* object-fit: cover; */
    width: 115%;
    height: auto;
    /* width: 29.8rem;
    height: 27.6875rem; */
    /* width: 25rem;
    height: 26.5rem; */
    /* height: auto; */
    min-height: auto !important;
    background-attachment: fixed;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: .3rem; */
    /* margin-left: 5rem; */
}

/* Calculator btn */
.calculator-button {
    /* background-color: #FFF4F3 !important; */
    background-color: #779EFF !important;
    /* color: #3D5F87 !important; */
    color: #fff !important;
    font-family: var(--onest);
    min-height: 3.5rem !important;
    width: 11.9rem !important;
    margin-left: 1.2rem;
    font-weight: 500 !important;
    font-size: .95rem !important;
    border-radius: 2px !important;
    border: 1px solid #DBC7C7 !important;
    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */
}

.calculator-button2 {
    background-color: #FF135A !important;
    /* color: #3D5F87 !important; */
    color: #fff !important;
    font-family: var(--onest);
    min-height: 3.5rem !important;
    width: 14rem !important;
    font-weight: 600 !important;
    font-size: .95rem !important;
    border-radius: 8px;
    border: 1px solid #FF135A !important;

}

.quote-btn-banner {
    background-color: var(--primary-color) !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
    font-family: var(--onest);
    min-height: 3.5rem !important;
    width: 10.125rem !important;
    /* margin-left: 1.2rem; */
    font-weight: 500 !important;
    font-size: .95rem !important;
    border-radius: 2px !important;
    border: none !important;
    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */
}

#banner-review1 {
    width: 162px;
    height: 68px;
    margin: 60px 0px 0px 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px dashed #DEDEDE;
    background: #FFF;
    color: var(--neutral, #6B6B6B);
    font-family: var(--onest);
    font-size: .8rem;
    font-weight: 600;
    line-height: 19px;
}

#banner-review2 {
    width: 162px;
    height: 68px;
    margin: 60px 0px 0px 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px dashed #DEDEDE;
    background: #FFF;
    color: var(--neutral, #6B6B6B);
    font-family: var(--onest);
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
}

#banner-review3 {
    width: 150px;
    height: 68px;
    margin: 60px 0px 0px 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px dashed #DEDEDE;
    background: #FFF;
    color: var(--neutral, #6B6B6B);
    font-family: var("--onest");
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
}

/* end top banner */

/* start anual summary */
.countNumber {
    color: #FFF;
    font-size: 2rem;
    font-family: var(--onest);
    font-weight: 700;
}

.countTitle {
    color: #C7C7C7;
    font-size: .9rem;
    font-family: var(--onest);
    font-weight: 600;
}

.summary-section {
    background-color: var(--secondary-color);
    /* border-radius: 5px; */
    padding: 1.5rem;
    /* box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.15); */
}

/* end anual summary */

/* start All client */
.proudly-text {
    color: #292929;
    font-family: var(--young);
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.9rem;
    /* 160% */
}

.proudly-text2 {
    color: var(--neutral);
    font-family: var(--onest);
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    width: 80%;
    /* 160% */
}

.we-help-btn {
    width: 200px;
    height: auto;
    color: #384046 !important;
    background-color: #D3DDFF !important;
    color: var(--neutral);
    font-family: var(--onest);
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    border: none !important;
    /* 160% */
}

/* end all client */

.logo-img {
    width: 126px;
    height: 55px;
    padding: 5px 10px;
}

/* start dashboard */
.dash-img-containter {
    margin-top: -15rem;
    width: 100%;
    height: auto;
}

floating-img {
    max-width: 100%;
    min-height: 550px;
}

.dasboard-container {
    background-image: url("../images/home/dashboard-back.png");
    object-fit: cover;
    background-size: cover;
    background-position: center;
    min-width: 100%;
    min-height: 700px;
    /* position: relative; */
}

/* .dash-p-section {
    margin-top: 13rem;
} */

.floating-image {
    width: 50%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 25%;
    right: 25%;
    padding: 2rem;


    /* bottom: 0; */
    /* margin-right: 20px;
    transform: translateX(-50%); */
}

.book-demo-btn {
    /* background-color: #1CA9AB !important; */
    background-color: #779EFF !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
    font-family: var(--onest);
    min-height: 3.5rem !important;
    width: 10.18rem !important;
    /* margin-left: 1.2rem; */
    font-weight: 500 !important;
    font-size: 1rem !important;
    border-radius: 5px !important;
    transition: all 0.5s !important;
    cursor: pointer !important;
    border: none !important;
    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */
}

.book-demo-btn span {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    transition: 0.5s !important;
}

.book-demo-btn span:after {

    /* content: '\00bb' !important; */
    content: '\003E' !important;
    position: absolute !important;
    opacity: 0 !important;
    top: 0 !important;
    right: -20px !important;
    transition: 0.5s !important;
}

.book-demo-btn:hover span {
    padding-right: 25px !important;
}

.book-demo-btn:hover span:after {
    opacity: 1 !important;
    right: 0 !important;
}

.free-quote-btn {
    background-color: #FF135A !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
    font-family: var(--onest);
    min-height: 3.5rem !important;
    width: 10.18rem !important;
    /* margin-left: 1.2rem; */
    font-weight: 500 !important;
    font-size: 1rem !important;
    border-radius: 5px !important;
    transition: all 0.5s !important;
    cursor: pointer !important;
    border: none !important;
    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */
}

.free-quote-btn span {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    transition: 0.5s !important;
}

.free-quote-btn span:after {

    /* content: '\00bb' !important; */
    content: '\003E' !important;
    position: absolute !important;
    opacity: 0 !important;
    top: 0 !important;
    right: -20px !important;
    transition: 0.5s !important;
}

.free-quote-btn:hover span {
    padding-right: 25px !important;
}

.free-quote-btn:hover span:after {
    opacity: 1 !important;
    right: 0 !important;
}

/* .book-demo-btn {
    color: #fff !important;
    background-color: #1CA9AB !important;
    text-align: center;
    min-height: 2.5rem !important;
    font-family: var(--onest);
    width: 8.25rem !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    border-radius: 5px !important;
    transition: all 0.5s !important;
}

.book-demo-btn:hover {

    background-color: #28c1c4 !important;

} */

/* end dashboard */

/* start service*/
.service-container {
    background-color: #EDF5FF;
    height: auto;
    min-width: 100%;
    /* min-height: 130vh; */
    /* background: linear-gradient(179deg, #FFF 30.32%, rgba(255, 255, 255, 0.46) 68.98%, #F8F3F3 96.26%); */

}


/* .center-content {
    min-height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
/* .slick-prev:before, .slick-next:before {
     Change this to the color you want
} */
/* end service*/

/* start all in one app */

.all-one-container {
    background-color: #252E44;
    padding: 5rem;

}

/* .allInOne-p {
    color: #BDBDBD;
    font-family: var(--onest);
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5rem;
} */

.see-in-action-btn {
    background-color: #243044 !important;
    color: #FFF;
    font-size: 1rem;
    font-family: var(--onest);
    border: none !important;
    padding: 10px 15px 15px 10px !important;
    border-radius: 8px !important;
    position: absolute;
    top: 45%;
    left: 55%;
    transform: translate(-50%, -50%);
    text-align: center;
}

/* end all in one app */

/* start custom report */
.report-img {
    max-width: 100%;
    height: auto;
}

.accordion-button {
    box-shadow: none !important;
    border: none !important;
}

/* end custom report */

/* start offer quote */
.offer-quote {
    background-color: #6CA2E1;
    margin-top: -2rem;
    /* background-color: #38393A; */
}

/* end offer quote */

/* start client video */
.video-preview {
    width: 14rem;
    height: 12rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* end client video */

/* start testimonial */

.testimonial {
    border-radius: 8px;
    border: 1px solid #F3F3F3;
    background: #C2F4FF;
    box-shadow: 1px 20px 40px 0px rgba(0, 0, 0, 0.10);
    padding: 2rem;
    margin-right: 3rem;
}

.green {
    color: #fff;
}

.slick-list,
.slick-slider,
.slick-track {
    padding: 1rem 0;
}

.center .slick-center .project {
    transform: scale(1.3);
    z-index: 999;
    width: 110%;
    transition: all 400ms ease-in-out;
    pointer-events: all;
    opacity: 1;
}

.center .project {
    opacity: 0.3;
    pointer-events: none;
}

@media(max-width: 990px) {
    .center .slick-center .project {
        transform: scale(1);
    }

    .center .project {
        opacity: 1;
        pointer-events: all;
    }
}

@media(max-width: 480px) {
    .sub-h {
        display: none;
    }

    .star {
        display: none;
    }
}


/* scroll bar style  */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@keyframes header {
    0% {
        transform: translateY(-30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* end testimonial */
/* start Get in touch */
.get-in-touch {
    background-color: #243043;
    /* clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%); */
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 21.85%, #FFF 22.3%, #FFF5F5 100.97%); */

}

.get-in-touch-title {
    color: #779EFF;
    text-align: center;
    font-family: var(--young);
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 51px;
}

.get-in-touch-p {
    color: #F0EEEE;
    /* Heading 2 */
    font-family: var(--onest);
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    border-left: 3px solid #DADADA;
    height: 5rem;

}

.get-in-touch-p2 {
    color: #FF135A;
    font-family: var(--onest);
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    /* 166.667% */
    /* text-decoration-line: underline; */
}

.row {
    display: flex;
}

.line {
    /* column-rule-style: 1px solid #DADADA; */
    /* border-left: 3px solid #DADADA;
    height: 110px; */
    margin-bottom: 5rem;
}

/* end Get in touch */
/* start switch any device */
.switch-title {
    color: #243043 !important;
    font-size: 2.5rem;
    font-family: var(--young);
    font-weight: 400;
    line-height: 140%;
    word-spacing: 0px;
}

.switch-p {
    color: #656363;
    /* font-size: .9rem; */
    font: var(--onest);
    line-height: 1.4rem;
    text-align: justify;
    word-spacing: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.any-device-btn {
    background-color: #779EFF !important;
    color: #FFF;
    text-align: center;
    font-family: var(--onest);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6;
    width: 223px;
    height: 56px;
    padding: 1rem !important;
    border-radius: 8px !important;
    border: 1px solid #779EFF !important;
    /* margin-left: 1rem !important; */
}


.access-img {
    width: 100%;
    height: auto;
}

.app-img {
    margin-top: 5rem;
    width: 90%;
    height: auto;
}

/* end switch any device */

/* start social media */
.social-p {
    color: #FFF;
    text-align: center;
    font-family: var(--onest);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 135%;
    /* 135% */
    /* padding: 4rem; */
}

.fa {
    padding: 5px;
    font-size: 1.875rem;
    width: 1.875rem;
    text-align: center;
    text-decoration: none;
    margin: 2px 4px;
    border-radius: 50%;
    /* margin-right: 5px; */
    /* box-shadow: 4px 4px 4px #6CA2E1; */
    transform: translateY(0);
    /* Initial state */
    transition: transform 250ms;

}

.fa:hover {
    transform: translateY(-7px);
    /* Transformed state on hover */
    /* opacity: 0.7; */
}

.fa-facebook {
    background: #3B5998;
    color: white !important;

}

.fa-twitter {
    background: #000;
    color: white;
}

.fa-linkedin {
    background: #007bb5;
    color: white;
}

.fa-youtube {
    background: #bb0000;
    color: white;
}

.fa-instagram {
    background: #125688;
    color: white;
}

.social-icon {
    border: 1px solid #1CA9AB;
    border-radius: 50%;
    color: #1CA9AB;
    margin: 5px;
    padding: .4rem;
    transform: translateY(0);
    /* Initial state */
    transition: transform 250ms;
}

.social-icon:hover {
    transform: translateY(-7px);
    /* Transformed state on hover */
    /* opacity: 0.7; */
}

.social-icon2 {
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    margin: 5px;
    padding: .4rem;
    transform: translateY(0);
    /* Initial state */
    transition: transform 250ms;
}

.social-icon2:hover {
    transform: translateY(-7px);
    /* Transformed state on hover */
    /* opacity: 0.7; */
}


/* end social media */

/* start Quote section */
.quote-container {
    background-image: url(../images/home/quote-back2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    min-width: 100%;
    min-height: 100vh;

}

@media (max-width: 768px) {

    /* Add styles for smaller screens */
    .quote-container {
        background-size: contain;
        min-height: 50vh;
    }
}

.homeQuoteCard {
    height: auto;
    width: 100%;
    padding: 20px 35px 20px 35px;
    border-radius: 4px;
    border: 1px solid #DEDEDE;
    background-color: #FFF;
    box-shadow: 3px 6px 21px 0px rgba(0, 0, 0, 0.15);
    z-index: -1;
    margin-bottom: 3rem;
}

.partnarCard {
    height: auto;
    width: 100%;
    padding: 1.5rem 2rem 1.5rem 2rem;
    border-radius: 1.5rem;
    border: 1px solid #DEDEDE;
    background-color: #FFF;
    box-shadow: 3px 6px 21px 0px rgba(0, 0, 0, 0.15);
    z-index: -1;
    margin-bottom: 3rem;
}

.quote-title {
    /* padding-top: 3rem; */
    background-color: #1CA9AB;
    color: #FFF;
    font-size: 1.5rem;
    /* font-size: 44px; */
    font-family: var(--young);
    font-weight: 400;
    line-height: 3rem;
    padding: 10px;
    /* padding-bottom: 1em; */
}

.quote-p {
    color: #6B6B6B;
    font-size: 1rem;
    /* font-size: 44px; */
    font-family: var(--onest);
    font-weight: 400;
    line-height: 1.5rem;
    /* padding-bottom: 1em; */
}

.quoteCardTitle {
    color: #1CA9AB;
    font-family: var(--young);
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 1rem;

}

.form-label {
    font-size: .9rem !important;
    font-family: var(--onest);
    font-weight: 500;
    color: #FFF;

}

.form-control {
    height: 3rem !important;
    font-size: 1rem !important;
    font-family: var(--onest);
    font-weight: 500;
    background-color: #FFF !important;
    border-radius: 4px;
    border: 1px solid #D0D0D0;
}

.form-control:focus {
    border-radius: 4px !important;
    box-shadow: 0 0 4px 0 rgba(230, 0, 31, 0.9) !important;
}

.form-textarea {
    height: 6rem !important;
    font-size: 15px !important;
    line-height: 30px;
    font-family: var(--onest);
    font-weight: 500;
    text-align: left;
    background-color: #f7fafc !important;
    border: rgb(235, 235, 235) 1px solid !important;
    color: #555555;

}

.form-textarea:focus {
    border-radius: 2px !important;
    /* border-color:#2F9264 !important ;
    box-shadow:0 0 8px 0 rgba(47, 146,100, 0.5 ) !important; */
    box-shadow: 0 0 4px 0 rgba(230, 0, 31, 0.9) !important;
    /* box-shadow: 0 0 8px 0 rgba(0, 15, 230, 0.2) !important; */

}

.quoteDropdown {
    box-sizing: border-box;
    width: 100%;
    height: 3rem !important;
    font-family: var(--onest);
    color: var(--secondary-color);
    outline: none !important;
    text-align: left;
    background-color: #FFF !important;
    border-radius: 4px;
    border: 1px solid #D0D0D0;
    padding-left: 5px;

}

.quoteDropdown:focus {
    border-radius: 2px !important;
    box-shadow: 0 0 4px 0 rgba(230, 0, 31, 0.9) !important;
    border: rgb(235, 235, 235) 1px solid !important;
    outline: none;
}

.checkboxForm {
    font-size: 14px !important;
    font-family: var(--onest);
    text-align: justify;
    color: #7D7D7D;
}

.capcha {
    min-width: 100%;
    height: 100%;
}

/* .............quote section button........ */


.RequestQuoteBtn {
    border-radius: 4px !important;
    /* background-color: #228B22 !important; */
    background-color: #1CA9AB !important;
    border: none !important;
    color: #FFFFFF !important;
    text-align: center !important;
    font-size: 1rem !important;
    font-family: var(--onest);
    font-weight: 600;
    padding: 10px !important;
    width: 100% !important;
    height: 45px !important;
    transition: all 0.5s !important;
    /* cursor: pointer !important; */
    margin: 5px !important;
    border: none !important;
    /* box-shadow: rgba(197, 166, 166, 0.56) 0px 20px 40px 0 !important; */

}

.RequestQuoteBtn span {
    /* cursor: pointer !important; */
    display: inline-block !important;
    position: relative !important;
    transition: 0.5s !important;
}

.RequestQuoteBtn span:after {

    content: '\00bb' !important;
    position: absolute !important;
    opacity: 0 !important;
    top: 0 !important;
    right: -20px !important;
    transition: 0.5s !important;

}

.RequestQuoteBtn:hover span {
    padding-right: 25px !important;
    color: #FFFFFF !important;
}

.RequestQuoteBtn:hover span:after {
    opacity: 1 !important;
    right: 0 !important;
}

.quote-heading-div {
    padding: 1rem;
}

.quote-heading-div h2 {
    color: #555;
    text-align: center;
    font-family: var(--onest);
    /* 28px */
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    /* line-height: 44px;  */
    line-height: 157.143%;
    /* 157.143% */
}

.quote-heading-div p {
    color: #7D7D7D;
    text-align: center;
    font-family: Onest;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 21px */
}



/* ...... */
/* end Quote section */
/* start client feedback section */
/* customSlider.css */

.clientImage {
    height: 80PX;
    width: 110px;
    /* padding: 1.5rem;
    margin: 1rem;
    object-fit: cover; */
}

.feedback-image {
    height: auto;
    /* width: 251px; */
    width: 65%;
    border-radius: 10px;
    margin-bottom: 1rem;
    /* margin-top: 3rem;
    position: absolute; */
    /* height: 294px; */
}

/* .slick-list, .slick-slider, .slick-track {
    padding: 1rem 0 !important;
  } */
.feedback-container {
    /* background-image: url(../images/home/hand2.png); */
    background-repeat: no-repeat;
    background-position: bottom right;
    background-color: #252E44;
    display: flex;
    justify-content: center;
    /* Horizontally centers the content */
    align-items: center;
    /* Vertically centers the content */
    padding: 3rem;
    /* margin: 5rem; */
    border-radius: 15px;
    /* box-shadow: 0px 16px 21px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px); */
    /* box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.25); */
    /* backdrop-filter: blur(8.5px); */

}

.row-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* end client feedback section */

/* start footer */
.logo-footer {
    text-align: left;
    max-width: 100%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    height: auto;

}

.footerSection {
    min-height: 200px;
    box-shadow: 0 0 4px 0 rgba(138, 145, 243, 0.2);
    /* background-color: rgb(141, 143, 142); */
    background-color: #252E44;
    padding: 3rem;
}

.call-us {
    color: #FFFFFF;
    text-align: left;
    font-family: var(--young);
    font-size: 1.3rem;
    font-weight: 400;
    margin: 0;

}

.call-us-p {
    color: #FFFFFF;
    text-align: left;
    font-family: var(--onest);
    font-size: .9rem;
    font-weight: 400;
    margin: 0;
    /* 142.857% */
}

.FooterTitle {
    /* color: var(--primary-color); */
    color: #fff;
    text-align: left;
    font-family: var(--onest);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;


}

.footerUl {
    font-family: var(--onest);
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    word-spacing: 4px;
    /* padding: 10px 50px 30px 80px; */
    list-style-type: none;
    margin: 0;
    padding: 0;

}

.liOfficeAddress {
    color: #fff;
    list-style-type: square;
    float: left;
}

.aColor {
    color: #DEDEDE;
    font-family: var(--onest);
    font-weight: 00;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.aColor:hover {
    color: #E6001F !important;
}


.footerSocialLink {
    inline-size: 50px;
    color: #E6001F;
    margin-top: 1rem;
    font-size: 13px;
}

.footerSocialLink:hover {

    color: #ff6a7e !important;
}

.footerfontAwsome {
    color: #FFF;
    background-color: #243043;
    border-radius: 50%;
    /* width: 30px !important;
    height: 30px !important; */

}



.footerLinkDiv {
    display: flex;
    align-self: center;
}

.HhoWeLinkDiv {
    display: flex;
    align-self: center;
    margin-left: 500px;

}

.Storybutton {
    border-radius: 4px;
    /* background-color: #E6001F !important; */
    background-color: #1CA9AB !important;
    border: none !important;
    color: #FFFFFF !important;
    text-align: center !important;
    font-size: 1rem !important;
    font-family: var(--onest);
    font-weight: 600 !important;
    padding: 10px !important;
    width: 150px !important;
    height: 45px !important;
    transition: all 0.5s !important;
    cursor: pointer !important;
    margin: 1rem .5rem !important;
    border: none !important;
}

.Storybutton span {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    transition: 0.5s !important;
}

.Storybutton span:after {
    content: '\003E' !important;
    position: absolute !important;
    opacity: 0 !important;
    top: 0 !important;
    right: -20px !important;
    transition: 0.5s !important;
}

.Storybutton:hover span {
    padding-right: 25px !important;
}

.Storybutton:hover span:after {
    opacity: 1 !important;
    right: 0 !important;
}



.copyrightLink {
    text-decoration: none;
    color: #AEAEAE;
    font-family: var(--onest);
    font-size: 14px;
    font-weight: 500;

}

.copyrightSec {
    padding: 10px 10px 10px 10px;
    background-color: #3d4455;
}

/* end footer */
/* start solution */
.payroll-Image {
    height: auto;
    width: 100%;
    padding: 1rem 8rem 1rem 4rem;
    transform: translateY(0);
    /* Initial state */
    transition: transform 250ms;
}

.payroll-Image:hover {
    transform: translateY(-10px);
    /* Transformed state on hover */
}

.payroll-text {
    color: #243043;
    font-family: var(--onest);
    /* font-size: 20px; */
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 180%;
    padding: 0rem 0rem 2rem 0rem;
}

.payrollSerContainer {
    background-color: #FFF9F9;

}

/* .payroll-Image2 {
    height: auto;
    max-width: 100%;
    padding: 4rem 2rem 3rem 5rem;
    transform: translateY(0);
    transition: transform 250ms;

}

.payroll-Image2:hover {
    transform: translateY(-10px);
} */

.sub-banner-img {
    object-fit: cover;
    width: 70%;
    height: auto;
    min-height: auto !important;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    transform: translateY(0);
    /* Initial state */
    transition: transform 250ms;

}

.sub-banner-img:hover {
    transform: translateY(-10px);
    /* Transformed state on hover */
}

.sub-banner-img2 {
    width: 90%;
    height: auto;
    padding: 6rem 0rem 0rem 0rem;
    transform: translateY(0);
    /* Initial state */
    transition: transform 250ms;
}

.sub-banner-img3 {
    margin-top: 4rem;
    width: 100%;
    height: auto;
    /* padding: 6rem 0rem 0rem 0rem; */
    transform: translateY(0);
    /* Initial state */
    transition: transform 250ms;
}

.sub-banner-img2:hover {
    transform: translateY(-10px);
    /* Transformed state on hover */
}

/* end solution */
/* start industry expertise */
.serve-img {
    width: 5.5rem;
    height: 5.5rem;
    padding: 1.4375rem;
    margin: 0rem 2rem 2rem 0rem;
    border-radius: 13px;
    background: #F4F4F4;
}

.count-line {
    font-size: 20px;
    font-family: var(--onest);
    font-weight: 600;
    /* line-height: 240%; */

}

/* end industry expertise */
/* start about us */
.about-container {
    background-image: url("../images/about/001.webp");
}

.about-bnr-card {
    border-radius: 24px 24px 0 0;
    border: 3px solid #FFF;
    border-bottom: none;
    background: linear-gradient(180deg, rgba(3, 43, 67, 0.50) 0%, rgba(3, 43, 67, 0.50) 100%);
    backdrop-filter: blur(64.55000305175781px);
    color: #FFF;
    font-family: var(--onest);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    width: 100%;
    height: 263px;
    padding: 2rem;
}

.about-bnr-card1 {
    margin-top: -3rem;
    border-radius: 0 0 24px 24px;
    border: 3px solid #FFF;
    background: linear-gradient(180deg, rgba(209, 35, 42, 0.50) 0%, rgba(221, 112, 121, 0.50) 100%);
    backdrop-filter: blur(64.55000305175781px);
    color: #FFF;
    text-align: center;
    font-family: var(--young);
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    /* 116.667% */
    line-height: 35px;
    padding: 4.5rem 2rem;
}

.about-sub-title {
    color: #FFF;
    font-family: var(--onest);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 36px;
    /* 150% */
}

.about-description {
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    font-family: Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    /* 212.5% */
}

.why-choose-card {
    border-radius: 14.629px;
    background: #EEB7BA;
    padding: 1rem;
    width: 100%;
    height: 379.124px;
    margin: 1rem;
}

.makeing-switch-title {
    color: #032B43;
    font-family: var("--onest");
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    text-transform: lowercase;
}

.makeing-switch-subtitle {
    color: #FF135A;
    text-align: center;
    font-family: Onest;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    /* 191.667% */
    text-transform: capitalize;
}

.makeing-button {
    background-color: #FF135A !important;
    color: #FFF;
    text-align: center;
    font-family: var(--onest) !important;
    font-size: 64px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    border: none !important;
    /* 71.875% */
    text-transform: capitalize;
    border-radius: 24px !important;
    padding: 1.6rem !important;
    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */

}

.subscribe-btn {
    font-family: var(--onest) !important;
    font-size: 1.1rem !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    border: none !important;
    border-radius: 6.095px;
    background-color: #FF135A !important;
    padding: 1.1rem !important;
    width: 100%;
    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */
}

.learn-more {
    color: #FF135A;
    font-family: Onest;
    font-size: 1rem;
    font-weight: 400;
    line-height: 34px;
    cursor: pointer;
    /* 212.5% */
    text-decoration-line: underline;
}

/* for learn more details popup */
.modal-body {
    padding: 1rem 2rem !important;
    color: #000;
    text-align: justify;
}

.modal-header {
    padding: 1rem 2rem !important;
    color: #000;
    font-family: var("--onest");
    font-size: 1.5rem !important;
    font-weight: 700 !important;
}

.trust-card {
    border-radius: 8px 8px 132px 8px;
    background-color: #032B43;
    overflow: hidden;
    padding: 4rem 3rem 4rem 3rem;
    width: 100%;
    height: auto;
}

.trust-card img {
    position: absolute;
    top: -7%;
}

.trust-card2 {
    border-radius: 8px 8px 132px 8px;
    background-color: #D1232A;
    border: 1px solid #DADADA;
    overflow: hidden;
    padding: 4rem 3rem 4rem 3rem;
    width: 100%;
    height: auto;
}

.trust-card2-star {
    position: absolute;
    top: -7%;
}

.ball {
    position: absolute;
    left: 75%;
    bottom: -10%;
}

.consultation-txt {
    margin-left: 3rem;
    color: #555;
    font-family: Onest;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}

.onsultation-button {
    background-color: #FF135A !important;
    color: #fff !important;
    font-family: var(--onest);
    min-height: 3.5rem !important;
    width: 14rem !important;
    font-weight: 600 !important;
    font-size: .875rem !important;
    border-radius: 5px !important;
    border: 1px solid #FF135A !important;
    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */
}

.card-container {
    position: relative;
    width: 300px;
}

.image-above-card {
    position: absolute;
    top: 55%;
    /* Adjust positioning as needed */
    left: 10%;
    transform: translateX(-50%);
    z-index: 1;
}

.card-left {
    background-image: url("../images/about/Ellipse853.png");
    background-repeat: no-repeat;
    background-position: top left;
    /* background-color: #00BCD4 !important; */
    margin-top: 3rem;
    width: 100% !important;
    /* height: 31rem !important; */
    padding: 3rem;
    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
}

.about-text {
    color: #252E44;
    font-family: var(--young);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
}

.payroll-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    /* padding: 1rem; */
    transform: translateY(0);
    /* Initial state */
    transition: transform 250ms;
}

.payroll-img:hover {
    transform: translateY(-10px);
    /* Transformed state on hover */
}

.ca-trusted-img {
    width: 570.594px;
    height: 529px;
}

.join-img {
    width: 50px !important;
    height: 50px !important;
    margin: .5rem 1rem .3rem 1rem;
}

.tailor-card {
    width: 100%;
    height: 290px;
    /* height: auto; */
    border-radius: 8px !important;
    border: 1px solid #FFD7D7 !important;
    margin: .5rem;
    padding: 1rem;
}

.tailor-card-big {
    width: 100%;
    /* height: 23rem; */
    /* height: auto; */
    border-radius: 9px 9px 9px 140px;
    border: 3px dashed #FF135A;
    margin: .5rem;
    padding: 2rem 3rem 0rem 3rem;
}

.tailor-card-big Button {
    background-color: #779EFF !important;
    color: #FFF;
    text-align: center;
    font-family: var(--onest);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6;
    border-radius: 8px 8px 8px 21px !important;
    border: 1px solid #272525;
    padding: 1rem !important;
    margin: 4rem 3rem;
    /* margin-left: 1rem !important; */
}


/* .card {
    border-radius: 8px !important;
    border: 1px solid #F6F6F6 !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: red !important;
} */

.join-us-bg {
    background-color: #252E44;

    /* background: linear-gradient(180deg, #FFF 0%, rgba(244, 244, 244, 0.54) 44.52%, rgba(255, 255, 255, 0.00) 79.75%); */
    /* background: linear-gradient(180deg, #FFF 0%, rgba(244, 244, 244, 0.54) 44.52%, rgba(255, 255, 255, 0.00) 79.75%); */
}

/* start career */
.career-banner-img {
    object-fit: cover;
    width: 60%;
    height: auto;
    text-align: center;
    transform: translateY(0);
    transition: transform 250ms;
}

.career-banner-img:hover {
    transform: translateY(-10px);
}

/* end career */

/* start contact */
.contact-sec {
    max-width: 100%;
    background-image: url("../images/contact/image 33.png");
    background-color: #0E121C;
    min-height: auto !important;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    /* -webkit-clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 95%);
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 90%); */
}

.custom-form-control {
    /* margin-bottom: 5px;
    border-bottom: 1px solid #FFF !important; */
    background-color: #252E44 !important;
    border: none !important;
    color: #FFF !important;

}

.custom-form-control:focus {
    border-bottom: none !important;
    box-shadow: 0 0px 0 0 #fff !important;
}

/* .border-0 {
    border: 0!important;
}

.custom-form-control .border-bottom {
    border-bottom: 1px solid #2876c4!important; 
} */
/* end contact */
/* start accountant */
.program-container {
    background-image: url(../images/accountant//partnar-back.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    min-width: 100%;
    min-height: 100vh;
}

.program-card {
    height: auto;
    width: 100%;
    padding: 20px 35px 20px 35px;
    border-radius: 4px;
    border: 1px solid #DEDEDE;
    background-color: #FFF;
    box-shadow: 3px 6px 21px 0px rgba(0, 0, 0, 0.15);
    z-index: -1;
}

.progarm-btn {
    color: #FFF !important;
    text-align: center;
    font-size: .95rem !important;
    font-weight: 600 !important;
    width: 11rem !important;
    min-height: 2.5rem !important;
    padding: 10px 10px 10px 10px !important;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background: #62D798 !important;
}

/* end accountant */

.center-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

}

.vid-cick {
    background-color: #FF135A !important;
    border: none !important;
    /* border-radius: 50% !important; */
}

.vid-cick :hover {
    background-color: #FF135A !important;
    border: none !important;
    /* border-radius: 50% !important; */
}

.video-modal-body {
    height: 450px;
    background-color: #f8f8f8;
    margin-bottom: 5rem;
}

.modal-header {
    background-color: #6CA2E1;
}

.modal-title {
    font-size: 24px;
}

.modal-footer {
    background-color: #6CA2E1;
    border: none;
    text-align: left !important;
    justify-content: flex-start;
}

.modal-footer button {
    background-color: #FF135A;
    color: #fff;
}

.modal-footer button:hover {
    background-color: #f53d74;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

/* .responsive-modal-body {
    width: 100%;
    padding-bottom: 56.25%; 
    position: relative;
  }
  
  .responsive-modal-body .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }  */


/* ...............home page  quation  .......... */
.accordion-button {
    border-radius: 4px;
    border: 1px solid #EFEFEF;
    background-color: #FFF;
    margin-top: .3rem;
}

.quote-navbar {
    width: 100%;
    background-color: #1CA9AB;
    /* overflow: auto; */
    cursor: pointer;
}

.quote-navbar Button {
    /* float: right; */
    /* padding: 7px; */
    color: white !important;
    /* text-decoration: none; */
    font-family: var(--onest);
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    /* letter-spacing: 1px; */
    /* box-sizing: border-box; */
    border: none !important;
    background-color: #1CA9AB;
    width: 100%;
    /* Four links of equal widths */
    /* text-align: center; */
    border-left: 3px solid #fff;
}


.quote-navbar Button.active {
    background-color: #273044 !important;
}

.quote-navbar Button:hover {
    background-color: #1CA9AB !important;
}

.quote-navbar Button:focus {
    outline: none !important;
    box-shadow: none !important;
}

/* ...............Resourses FAQ TopBanner............... */
/* ........Industry news...... */

.IndustryNewsBanner {
    margin-top: 7rem;
    background-image: url("../images/header-toronto1.jpg");
    min-height: 380px;
    background-repeat: no-repeat;
    width: 100%;
    /* position: fixed; */
    background-size: cover;
    /* background-position: center; */
    /* background-attachment: fixed !important; */
}

.industryNewsCard {
    height: 90% !important;
    width: 100% !important;
    box-shadow: 0 0 8px 0 rgba(0, 15, 230, 0.2);
    /* padding: 6px 3px 6px 3px; */
    margin-bottom: 3rem;
}

.industrycardTtle {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    font-family: var(--onest);
    /* text-align: left; */
    color: #000 !important;
    word-spacing: 1px;
    ;
}

.industryImg {
    display: block;
    width: 100%;
    height: auto;
}

.industryImgoverly {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    height: 46%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: rgb(230, 0, 31, 0.5);

}

.industryImgoverly:hover {
    opacity: 1;
}

.industryicon {
    color: white;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    /* visibility: hidden; */
}

.industryicon:hover {
    color: #eee !important;

}

.commonDesc {
    color: #6B6B6B;
    font-size: 1rem;
    font-weight: 400;
    font-family: var(--onest);
    line-height: 1.5rem;
    text-align: justify;
    word-spacing: 0px;
}

.text-ellipsis {
    white-space: nowrap;
    /* Prevent text from wrapping to the next line */
    overflow: hidden;
    /* Hide any overflowing text */
    text-overflow: ellipsis;
    /* Show an ellipsis (...) when text overflows */
    width: 200px;
    /* Set the desired width of the container */
}

.custom-solution-item {
    background-color: #FFEBEC !important;
}

.custom-solution-item2 {
    /* width: 21.625rem;
    height: 4.375rem; */
    background-color: #FFF !important;
    border-radius: 4px;
    border: 1px solid #EEE;
    padding: 1rem;
}

/* .....Faq section payrol S. unified, dental, contractor........... */

/* .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    height: auto;
} */

/* .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  } */


.FAQcollapsible {
    /* background: rgb(39, 48, 68);
    background: linear-gradient(90deg, rgba(39, 48, 68, 1) 32%, rgba(39, 48, 68, 1) 68%, rgba(0, 188, 212, 1) 100%); */
    color: 212529;
    cursor: pointer;
    padding: 20px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    font-family: var(--onest);
    font-weight: 600;
    border-bottom: 5px solid #F2F2F2;
    background-color: #fff;
}

.factive,
.FAQcollapsible:hover {
    /* background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(239, 102, 118, 1) 35%, rgba(0, 212, 255, 1) 100%); */
    color: #212529;
    background-color: #F0EEEE;
}

.FAQcollapsible:after {
    content: '\002B';
    color: white;
    font-weight: 700;
    text-align: center;
    float: right;
    /* margin-left: 5px; */
    height: 21px;
    width: 20px;
    border-radius: 50%;
    background-color: #FF135A;
}

.factive:after {
    content: "\2212";
    border-radius: 50%;
    text-align: center;
    height: 21px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    background-color: #FF135A;
}

.FAQcontent {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #ffffff;
}

/* .................................. */
.FaqDescription {
    color: #555555;
    font-size: 15px;
    font-family: var(--onest);
    line-height: 1.5em;
    font-weight: 400;
    text-align: justify;
    margin: 15px;
    /* padding-bottom: 1rem; */
    /* padding: 6px; */
}

.faqLink {
    color: #E6001F;
    font-size: 15px;
    font-family: var(--onest);
    line-height: 1.5em;
    word-spacing: 2px;
    font-weight: 500;
    text-decoration: underline;

}

.faqLink:hover {
    color: #EF6676 !important;

}

/* ----------------Condition apply section......... */
.condition-card {
    height: auto;
    padding: 1.5rem;
    border-radius: 5px;
    /* box-shadow: 0 0 8px 0 rgba(0, 15, 230, 0.2); */
    box-shadow: 0 0 4px 0 rgba(177, 176, 176, 0.5);
}

.condition-card2 {
    height: auto;
    padding: 1rem;
    /* border-radius: 5px; */
    /* box-shadow: 0 0 8px 0 rgba(0, 15, 230, 0.2); */
    /* box-shadow: 0 0 4px 0 rgba(177, 176, 176, 0.5); */
}

.benefit-card {
    width: 100%;
    height: 90px;
    border-radius: 8px;
    border: 1px solid #B9FFD5;
    background-color: #FFF;
    box-shadow: 0px 16px 16px 0px rgba(114, 113, 113, 0.25);
    padding: 1rem;
}

.condition-subtitle {
    color: #525252;
    font-family: var(--onest);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 64px; */
    /* 457.143% */
}

.ConditionDesc {
    /* color: #7E7E7E; */
    color: #525252;
    font-family: var(--onest);
    font-size: .9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;

}

.ConditionUl {
    /* margin-top: 3rem; */
    color: #243044;
    /* color: #1CA9AB; */
    font-family: var(--onest);
    font-weight: 500;
    font-size: 16px;
    text-align: justify;
    line-height: 27px;
    /* word-spacing: 4px; */
    /* padding: 10px ; */
    list-style-type: square;
    margin-left: 30px;
    padding: 15px;
}

.ConditionSubTitle {
    font-size: .9rem;
    font-weight: 500;
    font-family: var(--onest);
    line-height: 145%;
    /* text-align: justify; */

}

.quoteCondition {
    font-family: var(--onest);
    font-weight: 500;
    color: #E6001F;
    font-size: 1rem;
    line-height: 2rem;
    text-decoration: none !important;
    cursor: pointer;
}

.quoteCondition:hover {
    color: #cd051f;
}

.condition {
    background-color: #243044;
    padding: 5rem 2rem;
    border-radius: 5px;

}

/* end quote quote condition */

/* start quote Details/ reply */
.ReplylogoImage {
    margin-top: 5rem;
    margin-bottom: 2rem;
    /* height: 120px;
    max-width: 300px; */
    text-align: center;
    align-items: center;
    justify-content: center;

}

.quoteDetailsCard {
    height: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    /* box-shadow: 0 0 8px 0 rgba(0, 15, 230, 0.2); */
    box-shadow: 0 0 4px 0 rgba(248, 44, 3, 0.5);
    margin-bottom: 5rem;
    /* background-color: #273044; */

}

.quoteReplyHeader {
    /* background-color: rgb(230, 0, 31, 0.5); */
    background-color: #273044;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 0 4px 0 rgba(0, 15, 230, 0.2);
    margin-left: -31px;
    margin-right: -31px;
}

.formTitle {
    color: white;
    font-family: var(--onest);
    font-size: 1.8rem;
    font-weight: 600;
}


.disablefromControl {
    background-color: rgb(60, 179, 113, 0.1) !important;
    color: #000;

}

.fromControlsign {

    margin-left: -1rem;
    /* margin-right: -10px; */

}

.replyBtn {
    border-radius: 4px;
    background-color: #1CA9AB !important;
    border: none !important;
    color: #FFFFFF !important;
    text-align: center !important;
    font-size: 1.2rem !important;
    font-family: var(--onest);
    font-weight: 700;
    padding: 10px !important;
    width: 350px !important;
    height: 50px;
    transition: all 0.5s !important;
    cursor: pointer !important;
    margin: 5px !important;
    border: none !important;

}

.replyBtn span {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    transition: 0.5s !important;
}

.replyBtn span:after {

    content: '\00bb' !important;
    position: absolute !important;
    opacity: 0 !important;
    top: 0 !important;
    right: -20px !important;
    transition: 0.5s !important;
}

.replyBtn:hover span {
    padding-right: 25px !important;
}

.replyBtn:hover span:after {
    opacity: 1 !important;
    right: 0 !important;
}

/* .....quotation success from design...... */
/* .quoteTopPage {
    background-color: #273044;
    min-height: 350px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.quoteCardSuccess {
    min-height: 29.5rem;
    padding: 10px 35px 10px 35px;
    /* top left bottom right  */
    box-shadow: 0 0 4px 0 rgba(0, 15, 230, 0.2);
    left: 0px;
    top: 0px;
    z-index: -1;
    margin-bottom: 3rem;
    border-radius: 20px;
}


.quotationTitle {
    color: #fff;
    font-family: var(--young);
    font-size: 29px;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
}

#SuccessMSgDiv {
    padding: 6rem 2rem 4rem 2rem;
}

.SuccessMsg {
    font-size: 1.25rem;
    font-family: var(--onest);
    font-weight: 700;
    text-align: center;
    color: #212529;
}

.SuccessMsgDesc {
    font-size: 1rem;
    font-family: var(--onest);
    font-weight: 500;
    text-align: center !important;
    color: #555555;

}

.replyMsg {
    background-color: #04AA6D;
    font-size: 1.1rem;
    font-family: 'Helvetica', Arial, Lucida, sans-serif;
    font-weight: 500;
    text-align: center;
    color: #fff;
    height: 4rem;
    padding: 1rem;
    border-radius: 5px;
}

.success-right-div {
    background:
        linear-gradient(#fff, #fff) padding-box,
        linear-gradient(25deg, rgba(0, 0, 0, .9), rgba(255, 19, 90, .8)) border-box;
    color: #000;
    border: 3px solid transparent;
    border-radius: 20px;
    /* border: 10px solid transparent;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(to right, rgba(0, 0, 0, .9), rgba(255, 19, 90, .8)); */

}

.success-right-div::after {
    border-radius: 40px;
}

.quotationRequestImg {
    min-height: auto;
    max-width: 31.875rem;
    object-fit: cover;
    padding: .5rem;
    border-radius: 30px;
}


/* .end quotation success from design */

/* start SolutionUsingVPM section */
.solution-left {
    background-image: url(../images/home/003.webp);
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 100%;
    height: 379px;
    margin: 1rem;
    padding: 2.5rem 2rem 2rem 2rem !important;
    background-color: #FFF;
    border-radius: 12px 100px 12px 12px !important;
    border: 2px dashed #779EFF !important;
}

.solution-right {
    background-image: url(../images/home/008.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 100%;
    height: 459px;
    margin: 1rem;
    padding: 2.5rem 2rem 2rem 2rem !important;
    background-color: #779EFF !important;
    border-radius: 12px 12px 12px 100px !important;
    border: 0px solid #D4E1FF !important;
    box-shadow: 0px 16px 21px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
}

.accountant-details {
    /* background-image: url(../images/home/003.webp); */
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 100%;
    /* height: 28.75rem; */
    height: auto !important;
    margin: 1rem;
    padding: 2rem 1rem 1rem 2rem !important;
    background-color: #FFF;
    /* border-radius: 8px 8px 56px 8px !important;
    border: 4px dashed #779EFF !important; */
}

.career-button {
    color: #FFF !important;
    text-decoration: none !important;
    font-family: var(--onest);
    min-height: 3rem !important;
    width: 8rem !important;
    /* margin-left: 1.2rem; */
    font-weight: 500 !important;
    font-size: .95rem !important;
    transition: all 0.5s !important;
    border-radius: 8px 8px 8px 21px !important;
    border: 2px solid #FFF !important;

    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */
}

.career-button:hover {
    background-color: #D4E1FF !important;
    color: #FFF !important;
}

/* end SolutionUsingVPM section */
/* start vpm team */
.team {
    margin-top: 7rem;
    margin-bottom: 7rem;
}

.nazim-title {
    color: #779EFF;
    font-family: var(--young);
    font-size: 30px;
    font-weight: 400;
    /* line-height: 36px; */
}

/* end vpm team */
/* start trade part */
.tradeImg {
    width: 200px;
    height: auto;
    margin: 1rem;
    /* padding: .5rem; */

}

.tradeImg:hover {
    transform: scale(1.1);
    transition: all .5s;
}

/* end trade part */

/* Example assumes default arrow classes; adjust as needed */
.slick-prev,
.slick-next {
    /* background-color: #fff !important;  */

    /* Add other styles as needed */
}

/* Optionally, change the color of the arrow icon itself */
.slick-prev:before,
.slick-next:before {
    color: #0E121C !important;
}

/* Example CSS to change dot color */
.slick-dots li button:before {
    color: red !important;
}

.campain {
    display: none;
    width: 100%;
    height: auto;
    padding: 0 !important;
    object-fit: cover;

    /* background-image: url("../images/quote/campain-banner.webp"); */
}

.terms-conditions {
    color: #D1242A !important;
    font-family: var(--onest) !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 1.5rem;
    /* text-decoration: underline; */
    border: none !important;
    background-color: #F7F7F7 !important;
}

.terms-conditions:focus {
    border-radius: none !important;
    box-shadow: none !important;
}

.home-offer-img {
    background-image: url("../images/quote/offer-payroll-home.webp");
    width: 100%;
    height: 12rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    /* padding: 5px 20px 0px 20px; */
}

/* START T4 PAGE */
/* Parent container for the form */
.form-container {
    padding: 2rem 4rem;
}

.t4-btn {
    color: #FFF !important;
    text-align: center;
    font-size: .95rem !important;
    font-weight: 600 !important;
    width: 100% !important;
    min-height: 2.5rem !important;
    padding: 10px 10px 10px 10px !important;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background: #1CA9AB !important;
}

.t4-img {
    width: 100%;
    height: auto;
    padding: 6rem 0rem 0rem 0rem;
}

.t4-btn-banner {
    background-color: var(--primary-color) !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
    font-family: var(--onest);
    min-height: 3.5rem !important;
    width: 13.2rem !important;
    /* margin-left: 1.2rem; */
    font-weight: 500 !important;
    font-size: 1rem !important;
    border-radius: 2px !important;
    border: none !important;
    /* box-shadow: rgba(102, 76, 76, 0.56) 0px 20px 60px 0 !important; */
}

.t4-btn-banner:hover {
    color: #FFF !important;
}

.t4-btn-banner:focus {
    /* outline: none !important; */
    box-shadow: none !important;
}

.quote-btn-banner:focus {
    box-shadow: none !important;
}

.nav-button:focus {
    box-shadow: none !important;
}

.login-btn:focus {
    box-shadow: none !important;
}


.t4-nav-button {
    width: 8rem !important;
    min-height: 2.5rem !important;
    border: none !important;
    background: linear-gradient(90deg, #FF135A 2.44%, rgba(245, 175, 25, 0.96) 99.54%);
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #FFFFFF !important;
    cursor: pointer;
    display: inline-block;
    font-family: var(--onest) !important;
    font-weight: 500 !important;
    font-size: .95rem !important;
    text-align: center !important;
    text-decoration: none !important;
    -webkit-animation: glowing 2000ms infinite;
    -moz-animation: glowing 2000ms infinite;
    -o-animation: glowing 2000ms infinite;
    animation: glowing 2000ms infinite;
}

/* .blink {
    animation: blink 2s steps(5, start) infinite;
    -webkit-animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink {
    to {
        visibility: hidden;
    }
} */

@-webkit-keyframes glowing {
    0% {
        background-color: #FF135A;
        -webkit-box-shadow: 0 0 0px #FF135A;
    }

    10% {
        background-color: #FF0000;
        -webkit-box-shadow: 0 0 10px #FF0000;
    }

    100% {
        background-color: #B20000;
        -webkit-box-shadow: 0 0 3px #B20000;
    }
}

@-moz-keyframes glowing {
    0% {
        background-color: #FF135A;
        -webkit-box-shadow: 0 0 0px #FF135A;
    }

    10% {
        background-color: #FF0000;
        -webkit-box-shadow: 0 0 10px #FF0000;
    }

    100% {
        background-color: #B20000;
        -webkit-box-shadow: 0 0 3px #B20000;
    }
}

@-o-keyframes glowing {
    0% {
        background-color: #FF135A;
        -webkit-box-shadow: 0 0 0px #FF135A;
    }

    10% {
        background-color: #FF0000;
        -webkit-box-shadow: 0 0 10px #FF0000;
    }

    100% {
        background-color: #B20000;
        -webkit-box-shadow: 0 0 3px #B20000;
    }
}

@keyframes glowing {
    0% {
        background-color: #FF135A;
        -webkit-box-shadow: 0 0 0px #FF135A;
    }

    10% {
        background-color: #FF0000;
        -webkit-box-shadow: 0 0 10px #FF0000;
    }

    100% {
        background-color: #B20000;
        -webkit-box-shadow: 0 0 3px #B20000;
    }
}

/* END T4 PAGE */

/* -------------Client Success Stories........ */
.clientSuccessStoriesBanner {
    background-image: url("../images/ClientSuccessStory/header-meeting3.jpg");
    min-height: 380px;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.ContactTopBannerOverly {
    /* position: absolute; */
    height: 380px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.successstoryImg {
    height: auto;
    width: 100%;
    border-radius: 10px !important;
    background-image: url("../images/ClientSuccessStory/nexus-2.jpg");
    /* cursor: pointer; */
    background-repeat: no-repeat;
    background-size: cover;

}

.successstoryImg2 {
    height: auto;
    width: 100%;
    border-radius: 10px !important;
    background-image: url("../images/ClientSuccessStory/mount-royal-dental.jpg");
    /* cursor: pointer; */
    background-repeat: no-repeat;
    background-size: cover;
}

.successstoryImg3 {
    height: auto;
    width: 100%;
    border-radius: 10px !important;
    background-image: url("../images/ClientSuccessStory/asif.png");
    /* cursor: pointer; */
    background-repeat: no-repeat;
    background-size: cover;
}

.successstoryImg4 {
    height: auto;
    width: 100%;
    border-radius: 10px !important;
    background-image: url("../images/ClientSuccessStory/dr-schindler.jpg");
    /* cursor: pointer; */
    background-repeat: no-repeat;
    background-size: cover;
}

.successstoryImg5 {
    height: auto;
    width: 100%;
    border-radius: 10px !important;
    background-image: url("../images/ClientSuccessStory/global-3.jpg");
    /* cursor: pointer; */
    background-repeat: no-repeat;
    background-size: cover;
}

.successstoryImgOverlay {
    background-color: rgba(0, 0, 0, 0.4);
    height: 26.125rem;
    /* padding: 15px 20px 15px 20px; */
    padding: 6px 3px 6px 3px;
    border-radius: 10px !important;
    /* background-repeat: no-repeat; */
    background-size: cover;
    /* margin: 5px 5px 5px 5px; */
}

.successStoryTitle {
    margin-top: 6rem;
    color: #ffffff;
    font-size: 1.5rem;
    font-family: var(--onest);
    line-height: 34px;
    font-weight: 600;
    text-align: center;
    /* word-spacing: 1px; */
    /* padding-bottom: 1rem; */
    /* padding: 8px; */
}

.successStorysubTitle {
    color: #ffffff;
    font-size: 1.3rem;
    font-family: var(--onest);
    /* line-height: 1.5em; */
    font-weight: 600;
    text-align: center;
    /* padding-bottom: 1rem; */
    /* padding: 5px; */
}

.successStoryPara {
    color: #ffffff;
    font-size: 1.1rem;
    font-family: var(--onest);
    /* line-height: 1.5em; */
    font-weight: 400;
    text-align: center;

    /* padding-bottom: 1rem; */
    padding: 0px 30px;
}

/* ...........Nexas Staffing client success stories....... */
.nexasStaffingBanner {
    min-height: 320px;
    background-color: #1CA9AB;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.nexasStaffingContainer {
    /* background-color: #F7F7F7; */
    width: auto;
    min-height: 500px;
    padding: 10px 20px 10px 20;
}

.nexastopTitle {
    font-family: var(--young);
    font-weight: 400;
    color: #ffffff;
    font-size: 2.75rem;
    /* margin-top: 6rem; */
    line-height: 62px;
    text-transform: uppercase;
}

.VPMredTitle {
    font-family: var(--onest);
    font-weight: 700;
    color: #cd051f;
    font-size: 42px;
    margin-top: 6rem;
    line-height: 62px;
}

.nexus1Img {
    height: auto;
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 2px 4px 1px rgba(74, 74, 75, 0.5);
}

.nexus2Img {
    height: auto;
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 2px 4px 1px rgba(74, 74, 75, 0.5);
    /* float: right; */
    /* margin-top: 4rem; */
    /* margin-left: 20px; */
}

.nexus3Img {
    height: auto;
    width: 95%;
    border-radius: 5px;
    box-shadow: 1px 2px 4px 1px rgba(74, 74, 75, 0.5);
    /* float: right; */
    /* margin-top: 4rem; */
    /* margin-left: 20px; */
}

.nexus4Img {
    height: auto;
    width: 95%;
    border-radius: 5px;
    box-shadow: 1px 2px 4px 1px rgba(74, 74, 75, 0.5);
}

.nexasClientSayCard {
    min-height: 340px;
    background-color: #273044;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;

}

.nexasCardDesc {
    color: #fff;
    font-size: 1.5rem;
    font-family: var(--onest);
    line-height: 130%;
    font-weight: 500;
    text-align: justify;
    padding: 1rem;
}

.nexasCardTitle {
    color: #fff;
    font-size: 1.8rem;
    font-family: var(--young);
    line-height: 130%;
    font-weight: 700;
    text-align: center;
}

.nexasCardName {
    color: #fff;
    font-size: 18px;
    font-family: var(--onest);
    font-weight: 600;
    text-align: center;
}

/* ..........page common title and description(insdustry serve,how we defferent,making the switch,)........ */
.commonTitle {
    font-size: 1.7rem;
    font-weight: 700;
    font-family: var(--young);
    text-align: left;
    line-height: 2.56rem;
    word-spacing: 1px;
}

.dentalTopBanner {
    background-image: url("../images/ClientSuccessStory/header-dentists.jpg");
    min-height: 30rem;
    min-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
}

.payrollServicesTopBannerOverly {
    /* position: absolute; */
    height: 30rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.spancolor {
    color: #1CA9AB;
}

.Dental {
    border-radius: 4px;
    background-color: #FF135A !important;
    border: none !important;
    color: #FFFFFF !important;
    text-align: center !important;
    font-size: 1rem !important;
    font-family: var(--onest);
    font-weight: 600;
    width: 180px !important;
    height: 40px;
}

/* End success story */

@media only screen and (max-width: 500px) {
    .g-recaptcha {
        transform: scale(0.80);
        transform-origin: 0 0;
    }
}
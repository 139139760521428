/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    /*  */
    html {
        font-size: 75%;
    }

    body {
        margin: 0;
        box-sizing: border-box;
    }

    /* New VPM */
    .vpm-logo {
        padding: .1rem;
    }

    /* start top Navbar */
    .nav-link::before {
        display: none !important;
    }

    .navbar-light .navbar-toggler-icon {
        margin-right: 2rem;
        color: #FF135A !important;
    }

    .navbar-toggler {
        color: #FF135A !important;
        margin-right: 1rem !important;
    }

    .custom-dropdown-item {
        width: 15rem !important;
    }

    .dropdown-menu {
        width: 94% !important;
        font-size: 15px !important;
        border: none !important;
        padding: 1rem !important;
        /* padding: 0rem !important; */
        background-color: #FFFBED !important;
    }

    .dropdown-item {
        background-color: #FFFBED !important;
    }

    .custom-solution-item2 {
        background-color: #FFFBED !important;
    }

    .custom-solution-item {
        background-color: #FFFBED !important;
        display: none !important;
    }

    .see-demo {
        display: none;
    }
    .about-img {
        display: none;
    }

    .quoteBtnForSmallScreen {
        display: block;
    }


    .navbar {
        position: relative;
        padding: 0px !important;
        background-color: #FEE;
        width: 100%;
        height: auto;
        /* margin-bottom: 3rem; */
        text-align: center;
        align-items: center;
        align-content: center;
    }

    .nav-link {
        margin-top: .4rem;
        font-size: 1.2rem;
        margin-left: 1rem;
        text-align: left;
    }

    .nav-item {
        margin-top: 1rem;
        font-size: 1.2rem;
        margin-left: 1rem;
        text-align: left;
    }

    #collasible-nav-dropdown {
        font-size: 1.4rem;
        margin-left: 1rem;
    }

    .banner-img {
        margin-top: 0;

    }

    .nav-button {
        height: 2.7rem;
        width: 90% !important;
        margin-bottom: 1rem;
    }

    .t4-nav-button {
        height: 2.7rem;
        width: 90% !important;
        margin-bottom: 1rem;
    }

    .t4-btn-banner {
        width: 100% !important;
    }

    .free-quote-btn {
        height: 2.7rem;
        width: 100% !important;
        margin-bottom: 1rem;
    }

    .quote-btn-banner {
        height: 2.7rem;
        width: 100% !important;
        margin-bottom: 1rem;
    }

    .calculator-button {
        height: 2.7rem;
        width: 100% !important;
        margin-bottom: 1rem;
        margin-left: 0px;
    }

    .book-demo-btn {
        height: 2.7rem;
        width: 100% !important;

    }

    .any-device-btn {
        width: 100%;
    }

    .nav-res {
        text-align: center;
        align-items: center;
        align-content: center;
    }

    .login-btn {
        height: 2.7rem;
        width: 90% !important;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }

    /* end top Navbar */
    .makeing-button {
        font-size: 2rem !important;
    }
    .team {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .image-above-card {
        display: none !important;
    }

    .offer-quote {
        margin-top: 0rem;

    }


    /* start top banner */
    .home-offer-img {
        background-image: url("../images/quote/VPM OFFER Mobile 5 (1).webp");
        width: 100%;
        height: 34rem;
    }

    .banner-title {
        font-size: 30PX;
    }

    .banner-p {
        width: 80% !important;
    }

    #banner-review1 {
        display: none;
    }

    .countTitle {
        margin: 10px 0px 40px 0px;
    }

    .proudly-text2 {
        width: 100%;
    }

    /* end top banner */
    .dash-img-containter {
        margin-top: -5rem;
        width: 100%;
        height: auto;
    }

    .campain {
        display: block;
    }

    .terms {
        display: none;
    }

    .com-side {
        display: none;
    }

    .work-with-img {
        display: none;
    }

    .home-offer-img {
        background-image: url("../images/quote/VPM OFFER Mobile 5 (1).webp");
        width: 100%;
        height: 34rem;
    }

    .floating-image {
        width: 100%;
        height: auto;
        position: absolute;
        top: 84%;
        left: 1%;
        /* margin-right: 20px; */
        /* transform: translateX(-50%); */
    }

    .homeQuoteCard {
        padding: 0;
        background-color: #FFF;
    }

    .form-container {
        padding: 0;
    }

    #collasible-nav-dropdown:hover {
        color: #FF135A !important;
        /* transition: height 0.8s ease-out !important; */
    }

    .topBanerTitle {
        margin-top: 1rem;
        font-size: 35px;
    }

    .SectionBetweenTopBaner {
        /* margin-top: 7rem; */
        min-height: 20rem;
    }

    .topBannerleftSide {
        min-height: 20rem;
    }

    .cal-button {
        display: none !important;
    }

    .topBannerHr {
        margin-top: 0px;
        height: 100%;
        margin-left: 1rem;
    }

    .topBannerRightSide {
        display: none;
    }

    .quotationTitle {
        font-size: 20px;
    }

    .form-control {
        /* height: 2.8rem !important;
        font-size: 11px !important; */
    }

    .quoteDropdown {
        height: 2.8rem !important;
        font-size: 11px !important;
    }

    .checkboxForm {
        font-size: 11px !important;
    }

    .solution {
        font-size: 14px !important;
        width: 180px !important;
        height: 40px !important;
    }

    .quotebutton {
        margin-bottom: 10px !important;
    }

    .app-img {
        margin-bottom: 3rem;
    }

    .access-img {
        margin-bottom: 3rem;
    }

    .joinSection {
        background-color: #F2F2F2;
        height: 560px;
    }

    .quoteCondition {
        font-size: 12px;
    }

    .Storybutton {
        font-size: 14px !important;
        width: 140px !important;
        height: 35px !important;
    }

    .restaurantOwnerwithlaptopImg {
        min-height: 90%;
        width: 80%;
    }

    .joindiv {
        margin-left: 40px;
    }

    /* ......why Switch To vpm.... */

    .serviceContainer {
        background-color: #273044;
        height: auto;
        -webkit-clip-path: none;
        clip-path: none;
        border-left: 10px solid #D22C4A;
    }

    .serviceContainer2 {
        height: auto;
        border-left: 10px solid #D22C4A;
    }

    .serviceCard {
        height: 85% !important;
        width: 90% !important;
    }

    .serviceMainTitleforTrust {
        margin-top: 5rem;
    }

    .trustedFixedBanner {
        margin-top: 5rem;
        height: 1150px !important;
        /* -webkit-clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 95%); */
        clip-path: none;
    }

    .trustedBannerOverlay {
        /* background-attachment: fixed; */
        height: 1150px !important;
        width: 100%;
        /* background-color: rgba(230, 0, 31, 0.8); */

    }

    .trustcaptionCard {
        /* width: 100%; */
        height: 95%;
    }

    .trustedDescriotion {
        color: #555555;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Lucida', 'Helvetica', Times Roman, Arial, Lucida, sans-serif;
        /* line-height: 1.5em; */
        font-weight: 500;
        text-align: justify;
        /* text-decoration: none solid rgb(85, 85, 85); */
        word-spacing: 0px;
    }

    .trustimgdiv {
        text-align: center;
        margin-bottom: 1rem;
    }

    .hrsummary {
        height: 2px;
        background-color: #E6001F;
        width: 50px;
    }

    .newsEventContainer {
        height: auto;
    }

    .newsEventDiv {
        margin-top: 3rem;
        height: 100%;
    }

    .topTitle {
        margin-top: 1rem;
    }

    .PayrollDesc {
        font-size: 16px;
    }

    .helpSmallBusinessImage {
        height: 90%;
        width: 90%;
    }

    .commonDesc {
        font-size: 1.2rem;
    }

    .qoutetopTitlePS {
        font-size: 29px;
    }

    .quoteComponentCyan {
        height: auto;
    }

    .sixSectionContainer {
        height: 100%;
        width: 100%;

    }

    .sixSectionContainerPra {
        font-size: 16px;
        line-height: 25px;
    }


    .quotationRequestImg {
        max-width: 30rem;
        margin-left: 0%;
    }

    .quotationRequestImg2 {
        margin-top: 1rem;
        min-height: 23rem;
        max-width: 30rem;
        margin-left: 0%;
    }

    .serviceCardDes {

        font-size: 16px;
        padding-bottom: 10px;
    }

    .industryImgoverly {
        height: 100%;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    html {
        font-size: 75%;
    }

    .campain {
        display: block;
    }

    .terms {
        display: none;
    }

    .com-side {
        display: none;
    }

    .navbar {
        position: relative;
        padding: 0px !important;
        ;
        background-color: white;
        width: 100%;
        height: auto;
        margin-bottom: 3rem;
    }

    .navbar-toggler-icon {
        color: #E6001F !important;
    }

    .navItem {
        font-size: 1.4rem;
        margin-left: 1rem;

    }

    #collasible-nav-dropdown {
        font-size: 1.4rem;
        margin-left: 1rem;
    }

    .dropdown-menu {
        width: 260px !important;
        font-size: 16px !important;

    }

    .navButtonLink {
        height: 2.7rem;
        width: 50% !important;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .home-offer-img {
        background-image: url("../images/quote/offer-payroll-mobile.jpg");
        width: 100%;
        height: 5rem;
    }

    .topBanerTitle {
        margin: 4rem;
        /* padding: 2rem;  */
    }

    .topBannerleftSide {
        min-height: 20rem;
    }

    .topBannerHr {
        margin-top: 0px;
        height: 100%;
        margin-left: 1rem;
    }

    .topBannerRightSide {
        display: none;
    }

    .quotationTitle {

        font-size: 22px;

    }

    .qoutetopTitlePS {
        font-size: 29px;
    }

    .qoutetopSubTitle2 {
        font-size: 18px;
    }

    .qoutetopSubTitle {
        font-size: 20px;
    }

    .qoutecomponentCyanSubTitle {
        font-size: 20px;
    }

    .quoteComponentCyanTitle {
        font-size: 29px;
    }

    .form-control {
        font-size: 12px !important;
    }

    .quoteDropdown {
        font-size: 12px !important;
    }

    .checkboxForm {
        font-size: 12px !important;
    }

    .vpminterfaceImg {
        margin-top: 2rem;
    }

    .joinSection {
        background-color: #F2F2F2;
        height: auto;

    }

    .restaurantOwnerwithlaptopImg {
        min-height: 90%;
        width: 80%;
    }

    .joindiv {
        margin-left: 90px;
    }

    .cardMainTitle {
        font-size: 24px;
    }

    .serviceContainer {
        background-color: #273044;
        height: auto;
        -webkit-clip-path: none;
        clip-path: none;
        border-left: 10px solid #D22C4A;
    }

    .serviceContainer2 {
        height: auto;
        border-left: 10px solid #D22C4A;
    }

    .serviceCard {
        height: 90% !important;
        width: 90% !important;
    }

    .summarySection {
        background-color: #F2F2F2;
        height: 520px;
        width: 100%;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    }

    .hrsummary {
        height: 2px;
        background-color: #E6001F;
        width: 50px;


    }

    .trustedFixedBanner {
        min-height: 1000px !important;
        -webkit-clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 95%);
        clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 94%);
    }

    .trustedBannerOverlay {
        background-attachment: fixed;
        position: absolute;
        min-height: 1000px !important;
        width: 100%;
        background-color: rgba(230, 0, 31, 0.8);

    }

    .trustimgdiv {
        text-align: center;
        margin-bottom: 1rem;
    }

    .trustcaptionCard {
        width: 100%;
        height: 95%;

    }

    .newsEventContainer {
        height: 700px;
    }

    .newsEventDiv {
        height: 100%;
    }

    .tradeImg {
        width: 150px;
        height: 90px;

    }

    .tradeImg:hover {
        transform: scale(1.1);
        transition: all .5s;
    }

    .qoutetopTitle {
        font-size: 29px;
    }

    .qoutetopSubTitle {
        font-size: 20px;
    }

    .helpSmallBusinessImage {
        height: 90%;
        width: 90%;
    }

    .sixSectionContainer {
        height: 100%;
        width: 100%;

    }

    .sixSectionContainerPra {
        font-size: 16px;
        line-height: 25px;
    }

    .logoImageFooter {
        text-align: left;
        width: 40%;
        height: 40%;

    }

    .industryImgoverly {
        height: 100%;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    html {
        font-size: 80%;
    }

    .home-offer-img {
        /* display: none; */
    }

    .navbar {
        position: relative;
        padding: 0px !important;
        ;
        background-color: white;
        width: 100%;
        height: auto;
        margin-bottom: 3rem;
    }

    .navbar-toggler-icon {
        color: #E6001F !important;
    }

    .navItem {
        font-size: 1.55rem;
        margin-left: 1rem;
    }


    #collasible-nav-dropdown {
        font-size: 1.4rem;
        margin-left: 1rem;
    }


    .navButtonLink {
        height: 2.7rem;
        width: 13rem;
    }

    .dropdown-menu {
        width: 260px !important;
        font-size: 16px !important;
    }

    .navButtonLink {
        height: 2.7rem;
        width: 50% !important;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .topBanerTitle {
        margin: 4rem;
        /* padding: 2rem;  */
    }


    .topBannerleftSide {
        min-height: 20rem;
        max-width: 100%;
    }

    .topBannerHr {
        margin-top: 0px;
        height: 100%;
        margin-left: 1rem;
    }

    .topBannerRightSide {
        display: none;
    }

    .summarySection {
        background-color: #F2F2F2;
        height: 550px;
        width: 100%;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    }

    .hrsummary {
        height: 2px;
        background-color: #E6001F;
        width: 50px;

    }

    .serviceMainTitle {
        margin-top: 8rem;
        font-family: 'Helvetica', Arial, sans-serif;
        font-size: 28px;
    }

    .trustedFixedBanner {
        min-height: 1000px !important;
        -webkit-clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 95%);
        clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 94%);
    }

    .trustedBannerOverlay {
        background-attachment: fixed;
        position: absolute;
        min-height: 1000px !important;
        width: 100%;
        background-color: rgba(230, 0, 31, 0.8);

    }

    .trustimgdiv {
        text-align: center;
        margin-bottom: 1rem;
    }

    .trustcaptionCard {
        width: 100%;
        height: 95%;

    }

    .errorOmissionsImg {
        height: 90%;
        width: 95%;
    }

    .restaurantOwnerwithlaptopImg {
        min-height: 16.6rem;
        width: 22rem;
        border-radius: 5px;
        margin-top: 5rem;
    }

    .industryImgoverly {
        height: 100%;
    }

}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    html {
        font-size: 80%;
    }

    #collasible-nav-dropdown {
        font-size: 1rem;
    }

    .navItem {
        font-size: 1rem;
    }

    .vpmlogo {
        height: 3.4rem;
        z-index: -1;
        max-width: 50%;
    }

    .summarySection {
        background-color: #F2F2F2;
        height: 280px;
        width: 100%;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    }

    .hrsummary {
        height: 2px;
        background-color: #E6001F;
        width: 50px;


    }

    .industryImgoverly {
        height: 100%;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
    html {
        font-size: 100%;
    }

    #collasible-nav-dropdown {
        font-size: 1rem;
    }

    .navItem {
        font-size: 1rem;
    }

    .vpmlogo {
        height: 3.4rem;
        z-index: -1;
        max-width: 70%;
    }

    .navButtonLink {
        min-height: 2rem !important;
        width: 10rem !important;
        font-size: .8rem !important;

    }


}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}